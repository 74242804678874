const Navbar = () => {
    return (
        <nav className="w-full fixed bottom-3">
            <div className="flex justify-between items-center w-4/5 bg-gray-800 p-4 rounded-lg">
                <a href="/" className="text-white hover:underline">Home</a>
                <a href="/projects" className="text-white hover:underline">Projects</a>
                <a href="/articles" className="text-white hover:underline">Articles</a>
                <a href="/about" className="text-white hover:underline">About</a>
            </div>
        </nav>
    );
};
export default Navbar;