import Navbar from '../components/navbar';

const Projects = () => {
    const projects = [
        {
            name: "notion-echo",
            description: "A Telegram bot to write your notes on notion as your were chatting with a friend",
            url: "https://github.com/fulviodenza/notion-echo",
            status: "ongoing"
        },
        {
            name: "torproxy",
            description: "A swiss army knife to instrumento your Kubernetes cluster with Tor utilities",
            url: "https://github.com/fulviodenza/torproxy",
            status: "wip"
        },
        {
            name: "rankore",
            description: "A discord bot to track user activity on servers",
            url: "https://github.com/fulviodenza/rankore",
            status: "ongoing",
        },
        {
            name: "pills-of-cs",
            description: "A telegram bot to retrieve pills of cs and give a look at my notebook",
            url: "https://github.com/fulviodenza/pills-of-cs",
            status: "archived"
        },
        {
            name: "z80-emu",
            description: "A toy-emulator of the Zilog z80 CPU based on the official documentation, written in C",
            url: "https://github.com/fulviodenza/z80-emu",
            status: "archived"
        },
        {
            name: "covid-notify",
            description: "An application to get notification about Covid-19 news",
            url: "https://github.com/fulviodenza/covid-notify",
            status: "archived"
        },
        {
            name: "ocaml-interpreter",
            description: "A university project in which I had to extend an interpreter written in Ocaml with some features",
            url: "https://github.com/fulviodenza/ocaml-interpreter",
            status: "archived"
        },
    ];

    return (
    <>
        <div className="flex flex-col mx-auto py-10">
            <h1 className="text-4xl font-bold mb-6">Projects</h1>
            <p className="text-gray-300 mb-4">
            My projects are all available on{" "}
            <a href="https://github.com/fulviodenza?tab=repositories" className="text-blue-500 underline">GitHub</a>, 
            but here's a list of some of the main projects I have worked on:
            </p>
            <ul className="space-y-4">
            {projects.map((project) => (
                <li key={project.name} className="border-b border-gray-700 pb-4">
                    <a
                    href={project.url}
                    className="text-xl font-semibold url"
                    >{project.name}</a>
                    <p className="text-gray-400 mt-2">{project.description}</p>
                    <p className="text-gray-500">Status: {project.status}</p>
                </li>
            ))}
            </ul>
        </div>
        <div className='flex justify-center ml-60 items-center w-full mt-auto'>
            <Navbar />
        </div>
    </>
    );
};

export default Projects;
