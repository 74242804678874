import Navbar from "../components/navbar";

const Articles = () => {
const articles = [
	{
        title: "notion-echo: from the Second Brain idea to the telegram bot project",
        url: "https://fulviodenza.substack.com/p/notion-echo-from-the-idea-to-the?r=1nl29b",
	},
    {
        title: "A practical introduction to Kubernetes internals",
        url: "https://fulviodenza.substack.com/p/a-practical-introduction-to-kubernetes",
    },
    {
        title: "Event-Driven Architecture in Rust",
        url: "/articles/event-driven-architecture-rust.html",
    },    
	{
        title: "AWS Cognito API for Golang Authentication",
        url: "https://medium.com/faun/aws-cognito-authentication-api-for-golang-d68725ecc440",
    },
];

return (
    <>
    <div className="flex flex-col py-10">
        <h1 className="text-4xl font-bold mb-6">Articles</h1>
        <ul className="space-y-4">
        {articles.map((article) => (
            <li key={article.name} className="border-b border-gray-700 pb-4">
            <a
                href={article.url}
                className="text-xl font-semibold url"
            >
                {article.title}
            </a>
            </li>
        ))}
        </ul>
    </div>
    <div className="flex justify-center ml-60 items-center w-full mt-auto">
        <Navbar />
    </div>
    </>
);
};

export default Articles;
