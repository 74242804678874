import Navbar from "../components/navbar";

const skills = [
	{
    title: "Programming",
    text: "Go, Rust, PostgreSQL, Kubernetes, Docker",
	},
	{
    title: "Languages",
    text: "English, Italian, Spanish, Catalan, Russian (WIP)",
	},
	{
    title: "Other",
    text: "Mathematics, Piano",
	},
];

const About = () => {
    return (
        <>
            <div className="flex flex-col py-10">
                <h1 className="text-4xl font-bold mb-6">Skills</h1>
                <ul className="space-y-4">
                {skills.map((skill) => (
                    <li className="border-b border-gray-700 pb-4">
                    <h2 className="url">{skill.title} </h2>
                    <p>{skill.text}</p>
                    </li>
                ))}
                </ul>
            </div>
            <div className='flex justify-center ml-60 items-center w-full mt-auto'>
                <Navbar />
            </div>
        </>
    );
};

export default About;
